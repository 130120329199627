import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IAuthUser } from '../interfaces/users.interface';
import { InactivityTriggerService } from './session-monitor/inactivity-trigger.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    @Inject(InactivityTriggerService) private inactivityTriggerService: InactivityTriggerService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = getUserToken();
    const headersConfig = {
      Authorization: token ? `Bearer ${getUserToken()}` : ''
    };
    const reqIntercept = req.clone({ setHeaders: headersConfig });
    this.inactivityTriggerService.resetActivity();
    return next.handle(reqIntercept).pipe(
      catchError((response: HttpErrorResponse) => {
        const { error } = response;
        return throwError(() => error);
      })
    );
  }
}

export const getUserToken = () => {
  const authUser = localStorage.getItem('authUser');
  if (authUser) {
    const user = JSON.parse(authUser) as IAuthUser;
    return user.accessToken;
  }
  return null;
};
